.About{
    padding-top: 50px;
    width: 65%;
    margin: 0 auto;
    margin-bottom: 80px;
    display: flex;
}

.About img {
    width: 400px;
    margin-top: 17px;
}
.about-text{
    margin: 0px 25px;
}
.about-text p{
    font-size: 14px;
    margin: 0,0px,0,0;
    padding: 0,0;
}

.about-text a{
    font-family: 'Josefin Sans', sans-serif;
    font-size: 14px;
    margin: 0px;
  }

@media only screen and (max-width: 600px) {
    .About{
        display: inline;
    }

    .about-images{
        text-align: center;
    }
    .About img {
        max-width: 88%;
        margin-top: 12px;
        margin-bottom: 12px;
    }
}
  