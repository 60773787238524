@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");

.gallery-item-content {
  padding-top: 20px;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 80px;
}
.gallery-item-content p {
  overflow-wrap: break-word;
  font-size: 16px;
}

.gallery-item-content h1 {
  text-align: center;
}

.gallery-item-content h3 {
  font-size: 24px;
  margin-top: 15px;
  margin-bottom: 7px;
}

.gallery-item-content p img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

.gallery-list {
  max-width: 70%;
  margin: 10px auto;
  margin-top: 10px;
  /* background-color: red; */
}

.gallery-item {
  position: relative;
  text-align: center;
  margin: 0px 0px 0px 0px;
  /*width: 24.0%;
      height: 20vw;
      padding: 0.5%; */
}

.hover-color {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.gallery-item:hover p {
  font-size: 28px !important;
}
.gallery-item:hover p {
  opacity: 1;
}
.gallery-item:hover img {
  filter: blur(2px);
}

.gallery-item:hover .hover-color {
  background-color: rgba(36, 36, 36, 0.432);
}

.gallery-item img {
  position: relative;
  object-fit: contain;
  background-color: white;
  /* transition-duration: 0.2s; */
}

.gallery-item-content a {
  text-decoration: none;
  font-weight: bold;
}

.gallery-item p {
  z-index: 2;
  opacity: 0;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: white;
  /* transition-duration: 0.2s; */
}

.gallery-list a {
  display: block;
  font-size: 0px;
  margin: 0px 0px 0px 0px;
}

@media only screen and (max-width: 600px) {
  .gallery-item:hover p {
    font-size: 14px !important;
  }
  .gallery-list {
    margin: auto !important;
    width: 97% !important;
    max-width: 100% !important;
  }
  .gallery-item-content {
    margin: auto !important;
    width: 90% !important;
    max-width: 100% !important;
  }
  .gallery-item-content img {
    width: 90% !important;
    object-fit: contain;
    height: auto !important;
  }
}
