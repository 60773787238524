@import url(https://fonts.googleapis.com/css?family=Josefin+Sans&display=swap);
@import url(https://fonts.googleapis.com/css?family=Sue+Ellen+Francisco&display=swap);
@import url(https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* <link href="https://fonts.googleapis.com/css?family=Quicksand&display=swap" rel="stylesheet"> */

body {
  background-color: rgb(255, 255, 255);
  font-family: 'Josefin Sans', sans-serif;
}


.essi-logo {
  position: absolute;
  text-align: center;
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 60px;
}

.essi-logo img {
  width: 650px;
  margin: 0px 0px 0px 0px;
  padding: 0px;
}

.top-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
  max-width: 70%;
  margin: auto;
  margin-top: 220px;
  margin-bottom: 25px;
  padding: 0;
}

.top-nav a {
  font-family: 'EB Garamond', serif;
  text-decoration: none;
  font-size: 22px;
  font-weight: bold;
  color: #000000;
  bottom: 0;
  align-self: flex-end;
}

.top-nav  #active{
  color: #da5d65;
}

.top-nav img {
  align-self: flex-end !important;
  width: 40px !important;
  bottom: 0 !important;
}

#IG {
  /* margin-left: auto; */
}

#essi-kauppa-logo img {
  margin-left: auto;
  width: 100px !important;
}

.top-nav-button {
  position: relative;
  display: flex;
  text-align: center;
  width: 100%;
  margin-top: 180px;
  margin-bottom: 0px;
  padding-bottom: 25px;
}

.top-nav-button img{
  position: absolute;
  left: 35%;
  bottom: 30px;
  width: 20px;
  vertical-align: middle;
}

.top-nav-button h2 {
  width: 80%;
  margin: auto;
  vertical-align: middle;
  font-size: 26px;
  border-bottom: 1px solid rgb(15, 15, 15);
}

.topsocial-row {
  margin-top: 80px;
  margin-left: auto;
  align-content: flex-start;
  position: relative;
}

.topsocial-row svg {
  font-size: 32px;
  cursor: pointer;
  align-self: flex-end;
  margin: 0px 10px 0px 0px;
  vertical-align: bottom;
}

.topsocial-row img {
  cursor: pointer;
  width: 80px !important;
  vertical-align: middle;
}

a {
  /* font-family: 'EB Garamond', serif;
  text-decoration: none;
  font-size: 22px;
  font-weight: bold;
  margin: 0px 35px 0px 35px; */
}

.social-row {
  border-top: 1px dashed rgb(0, 0, 0);
  display: block;
  justify-content: center;
  max-width: 70%;
  margin: 40px auto;
  font-size: 22px;
  height: 49px;
  text-align: center;
}

.social-row p {
  align-content: center;
  font-size: 16px;
  padding: 0px;
  margin: 0px;
  padding-top: 60px;
  padding-bottom: 60px;
}

.fa {
  padding: 3px 5px 3px 5px;
  text-align: center;
  text-decoration: none;
  margin: 0px 0px;
  border-radius: 25%;
}

.fa-instagram {
  background: #000000;
  color: white;
}

.fa-envelope-open {
  background: #000000;
  color: rgb(255, 255, 255);
}

#essi-ig-logo {
  position: absolute;
  bottom: 0;
  right: 110px;
}

@media only screen and (max-width: 1199px) 
  {
    .top-nav {
      max-width: 80%;
      width: 100%;
      display: flex !important;
      flex-direction: column;
      text-align: center;
      font-size: 100%;
      margin-top: 0px;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid rgb(15, 15, 15); 
    }

    .top-nav a {
      margin-top: 0px;
      font-size: 22px;
      align-self: center !important;
      margin-left: 0;
    }
  
    .top-nav svg {
      font-size: 30px;
    }
  
    .top-nav img {
      width: 25px !important; 
    }

    #IG {
      margin-top: 15px;
      margin-left: 0;
    }
  
    #essi-kauppa-logo img {
      width: 90px !important;
      margin-top: 10px;
      
    }
  
    #essi-ig-logo {
      position: relative;
      right: 0px;
    }
    #essi-ig-logo img{
      margin-right: 10px;
    }
    
  }

@media only screen and (max-width: 600px) {

  .App {
    width: 100%;
  }

  .essi-logo {
    /* background-color: rebeccapurple; */
    width: 100% !important;
    max-width: 100% !important;
  }

  .essi-logo img {
    max-width: 100%;
    /* width: 130%; */
    margin: 0px 0px 0px -0%;
    padding: 0px;
  }

  .topsocial-row {
    display: flex !important;
    flex-direction: column;
    max-width: 80%;
    width: 100%;
    margin: 20px auto 0px auto;
    padding-bottom: 25px;
    border-bottom: 0.1px solid rgb(0, 0, 0);
  }

  .Gallery {
    margin: auto;
    width: 100%;
  }
}

.gallery-item-content {
  padding-top: 20px;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 80px;
}
.gallery-item-content p {
  overflow-wrap: break-word;
  font-size: 16px;
}

.gallery-item-content h1 {
  text-align: center;
}

.gallery-item-content h3 {
  font-size: 24px;
  margin-top: 15px;
  margin-bottom: 7px;
}

.gallery-item-content p img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

.gallery-list {
  max-width: 70%;
  margin: 10px auto;
  margin-top: 10px;
  /* background-color: red; */
}

.gallery-item {
  position: relative;
  text-align: center;
  margin: 0px 0px 0px 0px;
  /*width: 24.0%;
      height: 20vw;
      padding: 0.5%; */
}

.hover-color {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.gallery-item:hover p {
  font-size: 28px !important;
}
.gallery-item:hover p {
  opacity: 1;
}
.gallery-item:hover img {
  -webkit-filter: blur(2px);
          filter: blur(2px);
}

.gallery-item:hover .hover-color {
  background-color: rgba(36, 36, 36, 0.432);
}

.gallery-item img {
  position: relative;
  object-fit: contain;
  background-color: white;
  /* transition-duration: 0.2s; */
}

.gallery-item-content a {
  text-decoration: none;
  font-weight: bold;
}

.gallery-item p {
  z-index: 2;
  opacity: 0;
  position: absolute;
  top: 45%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 20px;
  color: white;
  /* transition-duration: 0.2s; */
}

.gallery-list a {
  display: block;
  font-size: 0px;
  margin: 0px 0px 0px 0px;
}

@media only screen and (max-width: 600px) {
  .gallery-item:hover p {
    font-size: 14px !important;
  }
  .gallery-list {
    margin: auto !important;
    width: 97% !important;
    max-width: 100% !important;
  }
  .gallery-item-content {
    margin: auto !important;
    width: 90% !important;
    max-width: 100% !important;
  }
  .gallery-item-content img {
    width: 90% !important;
    object-fit: contain;
    height: auto !important;
  }
}

.gallery-item-content{
      padding-top: 20px;
      width: 80%;
      margin: 0 auto;
      margin-bottom: 80px;
}

.gallery-item-content p{
      overflow-wrap: break-word;
      font-size: 16px;
}

.gallery-item-content h1{

      text-align: center;
}

.gallery-item-content h3{
      font-size: 24px;
      margin-top: 15px;
      margin-bottom: 7px;
}

.gallery-item-content h4{
      font-size: 18px;
}

.gallery-item-content p img{
      max-width: 100%;
      display: block;
      margin: 0 auto;
}


.gallery-list{
      max-width: 70%;
      margin: 10px auto;
      margin-top: 10px;
      /* background-color: red; */
}

.fabric-text{
      text-align: center;
}

.fabric-text p{
      overflow-wrap: break-word;
      font-size: 14px;
}
.fabric-text h1{

      text-align: center;
}
.fabric-text h3{
      font-size: 24px;
      margin-top: 15px;
      margin-bottom: 7px;
}


.gallery-item{
      position: relative;
      text-align: center;
      margin: 0px 0px 0px 0px;
      /*width: 24.0%;
      height: 20vw;
      padding: 0.5%; */
}

.hover-color{
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;   
}

.gallery-item:hover p{
      font-size: 28px;
}
.gallery-item:hover p { opacity: 1; }
.gallery-item:hover img { -webkit-filter:blur(2px); filter:blur(2px); }

.gallery-item:hover .hover-color{
      background-color: rgba(36, 36, 36, 0.432);
}

.gallery-item img{
      /* position: relative;
      width: 100%;
      height: 100%;
      min-height: 220px;
      object-fit: cover;
      background-color: white; */
      /* transition-duration: 0.2s; */
}

.gallery-item p{
      z-index: 2;
      opacity: 0;
      position: absolute;
      top: 45%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      font-size: 20px;
      color: white;
      /* transition-duration: 0.2s; */
}

.gallery-list a{
      display: block;
      font-size: 0px;
      margin: 0px 0px 0px 0px;
}
.About{
    padding-top: 50px;
    width: 65%;
    margin: 0 auto;
    margin-bottom: 80px;
    display: flex;
}

.About img {
    width: 400px;
    margin-top: 17px;
}
.about-text{
    margin: 0px 25px;
}
.about-text p{
    font-size: 14px;
    margin: 0,0px,0,0;
    padding: 0,0;
}

.about-text a{
    font-family: 'Josefin Sans', sans-serif;
    font-size: 14px;
    margin: 0px;
  }

@media only screen and (max-width: 600px) {
    .About{
        display: inline;
    }

    .about-images{
        text-align: center;
    }
    .About img {
        max-width: 88%;
        margin-top: 12px;
        margin-bottom: 12px;
    }
}
  
